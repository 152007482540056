import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './shared.module.css'
import build from "../images/services/build.svg"
import design from "../images/services/design.svg"
import ecommerce from "../images/services/ecommerce.svg"
import prototyping from "../images/services/prototyping.svg"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <div className={styles.introContainer}>
			<h1 className={styles.staticTitle}>
				Our <br/>Services
			</h1>
			<p className={styles.staticSubTitle}>We help companies and startups to build efficient digital products with a human-first approach through proper UX & interaction design.</p>
		</div>

		<div className={styles.content}>


			<div className={styles.servicesContainer}>
				<div className={styles.contentContainer}>
					<h2 className={styles.minorSubTitle}>
						<img className={styles.minorSubTitleIllustration} src={design} alt="Design Logo" />
						<span>Strategy & Design</span>
					</h2>
					<p className={styles.description}>We believe we can go that extra mile by combining your expertise with our knowledge of building products. Through collaboration & research we want to understand the product needs & user-base. This helps us to capture the audiences attention through exceptional interaction and visual design based on a solid ux foundation</p>
					<div className={styles.list}>
						<ul>
							<li>Wireframing & User Journeys</li>
							<li>User Testing</li>
							<li>Roadmapping</li>
							<li>User Needs & Research</li>
						</ul>

						<ul>
							<li>Interface Design</li>
							<li>Interaction Design</li>
							<li>User Centered Design</li>
							<li>Brand Identity</li>
						</ul>
					</div>
				</div>
			</div>

      <div className={styles.contentContainer}>
        <h2 className={styles.minorSubTitle}>
          <img className={styles.minorSubTitleIllustration} src={ecommerce} alt="E-commerce Logo" />
          <span>Progressive Prototyping</span>
        </h2>
        <p className={styles.description}>Getting your products into the hands of people who’ll use them is essential, Our product sprints will enable you to realize, test, and validate a concept over a rapid time frame. It’s an ideation–led approach focused on efficiency & balancing user needs with the project budget. 🚀</p>
          <Link to="/services/progressive-prototyping" className={styles.btn}>Learn More</Link>
      </div>

      <div className={styles.servicesContainer}>
  			<div className={styles.contentContainer}>
  				<h2 className={styles.minorSubTitle}>
            <img className={styles.minorSubTitleIllustration} src={build} alt="Build Logo" />
  					<span>Product Makeover & MVP</span>
  				</h2>
  				<p className={styles.description}>Our team is committed to launching high grade, future-proof products, allowing you to focus on the right things. We focus on creating performant battle-tested solutions to enhance the user experience.</p>
  				<div className={styles.list}>
  					<ul>
  						<li>Bespoke web applications</li>
  						<li>Full stack development</li>
  						<li>Native Mobile Applications</li>
  					</ul>

  					<ul>
  						<li>Mobile first web development</li>
  						<li>Performance Review</li>
  						<li>Third Party (& API) integrations</li>
  					</ul>
  				</div>
  			</div>
      </div>

  			<div className={styles.contentContainer}>
  				<h2 className={styles.minorSubTitle}>
            <img className={styles.minorSubTitleIllustration} src={prototyping} alt="Prototyping Logo" />
  					<span>E-commerce</span>
  				</h2>
  				<p className={styles.description}>Shopify Store Setup, Shopify Theme Development, Performance & Site-speed Review, Feature Integration, Third party apps, SEO & Analytics</p>
  			</div>
        
      {/*
			<div className={styles.servicesContainerSmall}>

				<div className={styles.contentContainerSmall}>
					<div className={styles.contentContainer}>
						<h2 className={styles.minorSubTitle}>
							<img className={styles.minorSubTitleIllustration} src={ecommerce} alt="E-commerce Logo" />
							<span>E-commerce</span>
						</h2>
						<p className={styles.description}>Shopify Store Setup, Shopify Theme Development, Performance & Site-speed Review, Feature Integration, Third party apps, SEO & Analytics</p>
					</div>
					<div className={styles.contentContainer}>
						<h2 className={styles.minorSubTitle}>
							<img className={styles.minorSubTitleIllustration} src={prototyping} alt="Prototyping Logo" />
							<span>Rapid Prototyping</span>
						</h2>
						<p className={styles.description}>Clickable Prototypes, Product Definition & Research, Ideation & feature workshops, Functional MVP creation</p>

					</div>

				</div>
			</div>*/}
		</div>

  </Layout>
)

export default ServicesPage
